html {
  color: #000;
  font-family: 'Fira Sans Condensed', sans-serif;
  font-size: 16px;
  font-weight: 400;
  // background-color: #d6e4e6;
  background-color: var(--color1);
}

body {
  font-family: 'Fira Sans Condensed', sans-serif;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.w {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1280px;

  .at-max(1280px, {
    padding-left: 10px;
    padding-right: 10px;
  });
}

.site__center {
  flex: 1;
  padding-top: 20px;
  position: relative;

  .at-max(600px, {
    padding-top: 10px;
  });
}

.site__center:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  background-image: url(../img/bg241.jpg);
  z-index: -1;
  // opacity: .5;
}

.main {
  width: 100%;
  display: flex;
  position: relative;
}

.leftSide {
  width: 25%;
  padding: 0 20px 0 0;
  position: relative;

  .at-max(768px, {
    display: none;
  });
}

.rightSide {
  width: 75%;
  position: relative;

  .at-max(768px, {
    width: 100%;
  });
}

.mainSide {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.iBox {
  background-color: rgba(255, 255, 255, .9);
  border-left: 4px solid var(--color2);
  padding: 20px;
  margin-bottom: 20px;

  .at-max(600px, {
    padding: 10px;
    margin-bottom: 10px;
  });
}

.iBox__title {
  background: var(--color3);
  padding: 5px 10px;
  margin-bottom: 20px;
}

.iBox__title h1,
.iBox__title h2,
.iBox__title h3 {
  margin: 0;
}

.site__footer {
  margin-top: auto;
}

.header__area {
  width: 100%;
  background-color: var(--color1);
  // border-bottom: 4px solid var(--color2);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
  flex-wrap: wrap;


}

.logo1 {
  display: flex;
  flex-direction: column;

  .at-max(600px, {
    order: 0;
  });

.at-max(429px, {
  width: 100%;
});
}

.logo1 a {
  display: flex;
  flex-direction: column;
  text-decoration: none;

  .at-max(429px, {
    align-items: center;
  });
}

.logo1 span {
  font-size: 40px;
  font-weight: 700;
  line-height: 1.3;
  text-transform: uppercase;
  color: #fff;

  .at-max(429px, {
    text-align: center;
  });
}

.logo1 span:last-child {
  font-size: 18px;
  font-weight: 400;
  color: var(--color2);

  .at-max(768px, {
    font-size: 16px;
  });

.at-max(429px, {
  text-align: center;
});
}

.topMenu__area {
  z-index: 1000;
  position: relative;
  background: var(--color2);

  .at-max(768px, {
    display: none;
  });
}

.topMenu__outer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menuButton {
  width: 36px;
  height: 36px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  display: none;
  z-index: 2005;

  .at-max(768px, {
    display: block;
  });

.at-max(429px, {
  order: 1;
  margin-left: auto;
  margin-right: auto;
});
}

.menuButton span {
  display: block;
  position: absolute;
  height: 2px;
  width: 20px;
  background: #fff;
  /*border-radius: 9px;*/
  opacity: 1;
  left: 8px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

.menuButton span:nth-child(1) {
  top: 8px;
}

.menuButton span:nth-child(2) {
  top: 17px;
}

.menuButton span:nth-child(3) {
  top: 26px;
}

.menuButton.open span:nth-child(1) {
  top: 17px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

.menuButton.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

.menuButton.open span:nth-child(3) {
  top: 17px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.topMenu {
  display: flex;
  list-style: none;
}

.topMenu li.lev1 {
  position: relative;
  margin-right: 20px;
}

.topMenu li.lev1 a.hasInner {
  position: relative;
  padding-right: 20px;
}

.topMenu li.lev1 a.hasInner:after {
  content: "\f107";
  font-family: FontAwesome;
  position: absolute;
  right: 0;
  margin-right: 0;
  top: 50%;
  margin-top: -20px;
  z-index: 3;
  font-size: 14px;
}

.topMenu li.lev1>a {
  font-size: 20px;
  font-weight: 300;
  display: block;
  text-decoration: none;
  line-height: 40px;
  color: #fff;
  transition: all .3s;
}

.topMenu li.lev1.active>a,
.topMenu li.lev1:hover>a {
  color: var(--color3);
}

.topMenu li.lev1 ul {
  position: absolute;
  left: -10px;
  top: 40px;
  max-height: 0;
  overflow: hidden;
  transition: all .5s;
  background: var(--color1);
  width: auto;
}

.topMenu li.lev1:hover ul {
  max-height: 500px;
}

.topMenu li.lev2 {
  list-style: none;
  border-bottom: 1px solid var(--color2);
}

.topMenu li.lev2>a {
  display: block;
  text-decoration: none;
  line-height: 1.2;
  padding: 10px;
  font-size: 16px;
  font-weight: 300;
  transition: all .3s;
  color: #fff;
  white-space: nowrap;
}

.topMenu li.lev2>a:hover {
  background: var(--color2);
  color: var(--color3);
}

.adaptiveMenu__area {
  width: 100%;
  display: none;
  order: 10;
}

.adaptiveMenu__outer {
  width: 100%;
  padding: 5px 0 0;
  text-align: center;
}

ul.adaptiveMenu {
  margin: 0;
  padding: 0;
}

ul.adaptiveMenu li {
  display: block;
  text-align: center;
  position: relative;
}

ul.adaptiveMenu li a {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  position: relative;
  display: inline-block;
}

.adaptiveMenu li.alev1 a.hasInner {
  position: relative;
  padding-right: 10px;
}

.adaptiveMenu li.alev1 a.hasInner:after {
  content: "\f107";
  font-family: FontAwesome;
  position: absolute;
  right: 0;
  margin-right: -8px;
  top: 50%;
  margin-top: -12px;
  z-index: 3;
}

.adaptiveMenu li.alev2 a.hasInner:after {
  content: "\f107";
  font-family: FontAwesome;
  position: absolute;
  right: 0;
  margin-right: -8px;
  top: 50%;
  margin-top: -8px;
  z-index: 3;
}

li.alev1 {
  padding: 5px 0;
  position: relative;
}



li.alev2 {
  padding: 4px 0;
}

li.alev3 {
  padding: 2px 0;
}

ul.adaptiveMenu li.alev2 a {
  font-size: 14px;
  text-transform: none;
  line-height: 1.2;

}

ul.adaptiveMenu li.alev3 a {
  font-size: 12px;
  color: #7d7d7d;
  text-transform: none;
}

ul.adaptiveMenu li.active>a,
ul.adaptiveMenu li:hover>a {
  color: #fff;
  text-decoration: underline;
}

ul.adaptiveMenu {
  position: relative;
}

ul.adaptiveMenu ul {
  display: none;
  margin: 12px 0 0;
}

.holder {
  display: flex;
  align-items: center;
  /*width: 10px;*/
  height: 14px;
  position: absolute;
  right: -40px;
  top: 50%;
  -webkit-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
}

.holder i {
  font-size: 10px;
}




.header__info {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .at-max(600px, {
    order: 2;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding-top: 10px;
  });

.at-max(429px, {
  flex-direction: column;
});
}

.header__infoItem {
  font-size: 26px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  .at-max(768px, {
    font-size: 24px;
  });

}

.header__infoItem:last-child {
  margin-bottom: 0;
  font-size: 18px;
}

.header__infoItem i {
  color: #fff;
  margin-right: 10px;
}

.header__infoItem a {

  font-weight: 300;
  color: #fff;
  text-decoration: none;
  position: relative;
}


.footer__area {
  padding: 30px 0;
  border-top: 4px solid var(--hover1);
  background-color: var(--color1);
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  flex-wrap: wrap;
}

.footer span {
  line-height: 1.4;
}

.f1,
.f3 {
  width: 40%;
}

.f1 {
  .at-max(768px, {
    order: 1;
    width: 50%;
  });

.at-max(480px, {
  order: 2;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
});

}

.f2 {
  width: 20%;
}



.copyright {
  font-size: 18px;
  font-weight: 700;
  color: #fff;
}

.oferta {
  color: #fff;
  font-size: 10px;
  font-weight: 400;
  line-height: 1.4;
  display: block;
}

.policy {
  color: #fff;
  font-size: 10px;
  font-weight: 400;
  line-height: 1.4;
  display: block;
}

.dev {
  padding-top: 10px;
  font-size: 12px;
  font-weight: 400;
  color: #fff;
}

.dev a {
  color: var(--hover1);
  text-decoration: none;
}

.f3 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .at-max(768px, {
    order: 2;
    width: 50%;
  });

.at-max(480px, {
  justify-content: center;
  order: 1;
  width: 100%;
  margin-bottom: 10px;
  align-items: center;
});
}

.telephon {
  display: flex;
  align-items: center;
  margin-bottom: 7px;
  justify-content: flex-end;
  color: #fff;

  .at-max(480px, {
    justify-content: center;
  });
}

.telephon:last-child {
  margin-bottom: 0;
}

.telephon i {
  font-size: 16px;
  margin-right: 10px;
  color: #fff;
}

.telephon a {
  text-decoration: none;
  font-size: 16px;
  color: #fff;
}

.telephon a:hover {
  text-decoration: underline;
}

.telephon p {
  font-size: 14px;
  color: #fff;
  text-align: right;
  display: inline-block;
}

.map__area {
  width: 100%;
}

#map {
  width: 100%;
  height: 400px;
}

.baloon__top {
  font-size: 16px;
  font-weight: 700;
  color: var(--color1);
}

.baloon__description {
  font-size: 0.875rem;
  font-weight: 400;
  font-style: italic;
  color: #000;
}

.baloon__content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.baloon__content img {
  display: block;
  width: 220px;
  height: 119px;
}

.baloon__content a {
  font-size: 16px;
  font-weight: 700;
  color: var(--red1);
}

.baloon__footer {
  font-size: 0.75rem;
  line-height: 1;
  font-weight: 400;
  color: var(--color1);
}

.leftMenu__area {
  position: relative;
  margin-bottom: 20px;
}

.left__title {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 22px;
  font-weight: 300;
  text-transform: uppercase;
  line-height: 1.3;
  padding: 10px;
  color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: var(--color2);
  margin-bottom: 2px;
}

.leftMenu {
  list-style: none;
}

.leftMenu li {}

.leftMenu li a {
  font-size: 22px;
  font-weight: 300;
  display: block;
  line-height: 1.2;
  padding: 10px 10px;
  color: #fff;
  background: var(--color1);
  transition: all .3s;
  text-decoration: none;
  margin-bottom: 2px;
}

.leftMenu li a:hover,
.leftMenu li.active a {
  background: var(--color3);
  color: var(--color1);
}

.gallery__area {
  margin: 30px 0 10px;
}

.gallery__outer {
  width: 100%;
  /*max-width: 1280px;*/
  margin: 0 auto;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
}

.gallery__item {
  width: 25%;
  padding: 5px;

  .at-max(768px, {
    width: 33, 333%;
  });

.at-max(480px, {
  width: 50%;
});


.at-max(320px, {
  width: 100%;
});
}

.gallery__item a {
  display: block;
  width: 100%;
  position: relative;
}

.gallery__item a:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 100, 10, 0.5);
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(../img/eye.png);
  opacity: 0;
  transition: all .3s;
}

.gallery__item a:hover:after {
  opacity: 1;
}

.gallery__item img {
  display: block;
  width: 100%;
  /*width: 500px;*/
  /*height: 500px;*/
}