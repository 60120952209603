@media only screen and (max-width: 1280px) {}

@media only screen and (max-width: 1200px) {
  // .topMenu li.lev1>a {
  //   font-size: 20px;
  //   padding: 0 15px 0 15px;
  //   margin: 0 5px 0 0;
  // }

  // .topMenu li.lev2>a {
  //   padding: 5px;
  //   font-size: 14px;
  // }


}

@media only screen and (max-width: 1080px) {
  .logo1 span {
    font-size: 36px;
  }



  .topMenu li.lev1>a {
    padding: 0 8px 0 8px;
  }

  .leftMenu li a {
    font-size: 18px;
    padding: 5px;
  }


}

@media only screen and (max-width: 920px) {
  .logo1 span {
    font-size: 32px;
  }
}

@media only screen and (max-width: 768px) {}

@media only screen and (max-width: 768px) {

  h1 {
    font-size: 36px;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  h2 {
    font-size: 32px;
    text-transform: uppercase;
    margin-bottom: 8px;
  }

  h3 {
    font-size: 28px;
    text-transform: uppercase;
    margin-bottom: 6px;
  }

  h4 {
    font-size: 26px;
  }

  h5 {
    font-size: 24px;
  }

  h6 {
    font-size: 22px;
  }

  .text__outer {
    padding: 20px 5px;
  }

  .title {
    margin-bottom: 10px;
  }



  .footer__area {
    padding: 10px 0;
  }

  .f1 {}

  .f2 {
    order: 0;
    width: 100%;
    margin-bottom: 10px;
  }

  .f3 {}


}

@media only screen and (max-width: 600px) {

  .logo1 span {
    font-size: 24px;
  }

  .header__info a {
    font-size: 22px;
  }


  .content p,
  .content ul,
  .content ol,
  .content blockquote,
  .content table {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.3;
    margin: 0 0 5px 0;
  }


}

@media only screen and (max-width: 480px) {


  h1 {
    font-size: 32px;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  h2 {
    font-size: 28px;
    text-transform: uppercase;
    margin-bottom: 8px;
  }

  h3 {
    font-size: 24px;
    text-transform: uppercase;
    margin-bottom: 6px;
  }

  h4 {
    font-size: 22px;
  }

  h5 {
    font-size: 20px;
  }

  h6 {
    font-size: 18px;
  }




  .f3 {}
}

@media only screen and (max-width: 400px) {
  .logo1 span {
    font-size: 20px;
  }

  .header__info a {
    font-size: 18px;
  }
}

@media only screen and (max-width: 320px) {}