.mainSlider {
  width: 100%;
}

.swiper-container1 {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide1 {
  -webkit-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  height: 500px;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper__cadr {
  width: 100%;
  max-width: 1080px;
  display: flex;
  align-items: center;
}

.swiper__inner1 {
  background: rgba(60, 140, 219, .4);
  padding: 20px;
  -webkit-box-shadow: 0px 0px 20px -5px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 20px -5px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 20px -5px rgba(0, 0, 0, 0.75);
  width: 100%;
  max-width: 700px;
}

.swiper__title1 {
  color: #fefefe;
  font-size: 32px;
  line-height: 1.2;
  font-weight: 400;
  text-transform: uppercase;
  /*text-align: center;*/
  margin-bottom: 10px;
}

.swiper__text1 {
  color: #fefefe;
  font-size: 16px;
  line-height: 1.2;
  font-weight: 300;
  /*text-align: center;*/
  /*margin-bottom: 20px;*/
}

.swiper__link1 {
  text-align: right;
}

.swiper__link1 a {
  display: inline-block;
  background-color: #bababa;
  border-radius: 8px;
  color: #175e39;
  font-size: 24px;
  font-weight: 400;
  text-decoration: none;
  padding: 10px 20px;
  transition: all .3s;
}

.swiper__link1 a:hover {
  background: #3C8CDB;
  color: #fff;
}

.swiper-button-prev1 {
  background: rgba(60, 140, 219, .2);
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  top: 50%;
  margin-top: -21px;
  font-size: 30px;
}

.swiper-button-next1 {
  background: rgba(60, 140, 219, .2);
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  top: 50%;
  margin-top: -21px;
  font-size: 30px;
}

.swiper-pagination1 {
  bottom: 30px !important;
}

.swiper-pagination-bullet {
  opacity: 1;
  width: 18px;
  height: 18px;
  background-color: #b8b8b8;
  border: 2px solid #3C8CDB;
}

.swiper-pagination-bullet-active {
  opacity: 1;
  width: 18px;
  height: 18px;
  background-color: #3C8CDB;
  background-color: #3C8CDB;
}

.swiper-pagination i {
  font-size: 32px;
}