h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--color1);
  font-weight: 400;
}


h1 {
  font-size: 42px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

h2 {
  font-size: 38px;
  text-transform: uppercase;
  margin-bottom: 8px;
}

h3 {
  font-size: 34px;
  text-transform: uppercase;
  margin-bottom: 6px;
}

h4 {
  font-size: 26px;
}

h5 {
  font-size: 24px;
}

h6 {
  font-size: 22px;
}

.content {
  /*margin-bottom: 20px;*/
}

.content p,
.content ul,
.content ol,
.content blockquote,
.content table {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  margin: 0 0 10px 0;
}

.content p:last-child,
.content ul:last-child,
.content ol:last-child,
.content blockquote:last-child,
.content table:last-child {
  margin-bottom: 0;
}

.content ul,
.content ol {
  padding: 0 0 0 25px;
}

.content ul li,
.content ol li {
  margin: 0 0 5px 0;
  padding: 0 0 0 0;
}

.content ul li {
  list-style-image: url("../img/ulbg.png");
}

.content blockquote {
  color: #fff;
  border-left: 5px solid var(--color1);
  padding: 10px 20px;
  background: var(--hover1);
}

.content a {
  color: var(--color1);
  text-decoration: underline;
}

.content ol {
  counter-reset: li;
  padding: 0;
  list-style: none;
  margin: 20px 0;
}

.content ol li {
  position: relative;
  margin: 0;
  padding-left: 30px;
  color: #363636;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.content ol li:before {
  content: counter(li) '.';
  counter-increment: li;
  position: absolute;
  left: 0px;
  width: 25px;
  text-align: right;
  display: inline-block;
  color: var(--color1);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.content img {
  max-width: 100%;
  display: inline-block;
  margin-bottom: 10px;
  height: auto;
}

.content b,
.content strong {
  font-weight: bold;
}

.content i,
.content cite,
.content em,
.content var,
.content address,
.content dfn {
  font-style: italic;
}

.content table th,
.content table td {
  padding: 10px;
  border: 1px solid var(--color1);
}

table.clear {
  border-collapse: collapse;
  margin-bottom: 15px;
}

table.clear td {
  text-align: center;
  vertical-align: middle;
  border: 0;
}

.table_outer {
  max-width: 100%;
  overflow: auto;
  margin-bottom: 10px;
}

.table_outer table {
  min-width: 500px;
}