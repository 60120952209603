@font-face {
	font-family: "Fira Sans Condensed";
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url(../fonts/FiraSansCondensed-Light.ttf) format("truetype");
}

@font-face {
	font-family: "Fira Sans Condensed";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(../fonts/FiraSansCondensed-Regular.ttf) format("truetype");
}

@font-face {
	font-family: "Fira Sans Condensed";
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: url(../fonts/FiraSansCondensed-Italic.ttf) format("truetype");
}

@font-face {
	font-family: "Fira Sans Condensed";
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url(../fonts/FiraSansCondensed-Medium.ttf) format("truetype");
}

@font-face {
	font-family: "Fira Sans Condensed";
	font-style: italic;
	font-weight: 500;
	font-display: swap;
	src: url(../fonts/FiraSansCondensed-MediumItalic.ttf) format("truetype");
}

@font-face {
	font-family: "Fira Sans Condensed";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(../fonts/FiraSansCondensed-Bold.ttf) format("truetype");
}

@font-face {
	font-family: "Fira Sans Condensed";
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	src: url(../fonts/FiraSansCondensed-BoldItalic.ttf) format("truetype");
}