.form1__outer {
  background-color: var(--color07);
  /*background: #0BAA65;*/
  padding: 20px;
  border-radius: 10px;
}

.form1__outer_indexBottom {
  // background-image: url(../img/bg10.jpg);
}

.form1__outer_text {
  padding: 10px;
  border-radius: 0;
  // background-image: url(../img/bg10.jpg);
}

.form1__title {
  margin-bottom: 10px;
  text-align: center;
  font-size: 28px;
  color: #fff;
  font-weight: 300;
}

.form1 {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form1 input {
  width: 100%;
  height: 32px;
  background-color: #fff;
  border: 1px solid #fff;
  padding: 0 10px;
  font-size: 20px;
  font-weight: 300;
  box-sizing: border-box;
  display: inline-block;
  outline: none;
  margin-bottom: 10px;
  font-family: 'Fira Sans Condensed', sans-serif;
  border-radius: 6px;
}

.form1 input.error {
  border: 1px solid #f00;
}



.form1 input.work_email1 {
  display: none;
}

.form1 textarea {
  width: 100%;
  height: 105px;
  background-color: #fff;
  border: 1px solid #fff;
  padding: 10px;
  font-size: 20px;
  font-weight: 300;
  line-height: 1.2;
  box-sizing: border-box;
  display: inline-block;
  outline: none;
  margin-bottom: 10px;
  resize: none;
  font-family: 'Fira Sans Condensed', sans-serif;
  border-radius: 6px;
}

.form1 textarea.error {
  border: 1px solid #f00;
}

.submit1 {
  width: 100%;
  height: 32px;
  border: none;
  outline: none;
  display: inline-block;
  color: var(--color1);
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
  background-color: #fff;
  padding: 2px 15px;
  text-decoration: none;
  transition: all .3s;
  cursor: pointer;
  margin-right: auto;
  border-radius: 6px;
}

.submit1:hover {
  background: var(--hover1);
  ;
  color: #fff;
}

.form1__text {
  font-size: 12px;
  line-height: 1.2;
  font-weight: 300;
  color: #fff;
  margin-bottom: 10px;
}

.form1__text a {
  font-size: 12px;
  line-height: 1.2;
  font-weight: 300;
  color: #fff;
  text-decoration: underline;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1999;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  display: none;
}

.js_container1 {}

.popupCallBack {
  display: none;
  z-index: 5000;
  position: fixed;
  top: 20px;
  width: 400px;
  left: 50%;
  margin-left: -200px;
}


.formClose {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--hover1);
  ;
  background: #fff;
  border-radius: 7px;
}

.formClose i {
  font-size: 18px;
  color: var(--hover1);
  ;
}

.formZ__outer {
  padding: 15px;
  border-radius: 10px;
  background-color: var(--hover1);
}

.formZ__title {
  margin-bottom: 5px;
  text-align: center;
  font-size: 24px;
  color: #fff;
  font-weight: 300;
  padding: 0 15px;
}

.formZ {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.formZ input {
  width: 100%;
  height: 30px;
  background-color: #fff;
  border: 1px solid #fff;
  padding: 0 7px;
  font-size: 18px;
  font-weight: 300;
  box-sizing: border-box;
  display: inline-block;
  outline: none;
  margin-bottom: 8px;
  font-family: 'Fira Sans Condensed', sans-serif;
  border-radius: 6px;
}

.formZ input.error {
  border: 1px solid #f00;
}



.formZ input.work_emailZ {
  display: none;
}

.formZ textarea {
  width: 100%;
  height: 70px;
  background-color: #fff;
  border: 1px solid #fff;
  padding: 7px;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.2;
  box-sizing: border-box;
  display: inline-block;
  outline: none;
  margin-bottom: 8px;
  resize: none;
  font-family: 'Fira Sans Condensed', sans-serif;
  border-radius: 6px;
}

.formZ textarea.error {
  border: 1px solid #f00;
}

.submitZ {
  width: 100%;
  height: 32px;
  border: none;
  outline: none;
  display: inline-block;
  color: #59929c;
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
  background-color: #fff;
  padding: 2px 15px;
  text-decoration: none;
  transition: all .3s;
  cursor: pointer;
  margin-right: auto;
  border-radius: 6px;
}

.submitZ:hover {
  background: var(--hover1);
  color: #fff;
}

.formZ__text {
  font-size: 12px;
  line-height: 1.2;
  font-weight: 300;
  color: #fff;
  margin-bottom: 10px;
}

.formZ__text a {
  font-size: 12px;
  line-height: 1.2;
  font-weight: 300;
  color: #fff;
  text-decoration: underline;
}